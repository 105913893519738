<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Campaign - {{this.form.type}} new entity
			el-form(:form="form")
				el-row(:gutter="12")
					el-col(:xs="24" :sm="8")
						el-form-item(label="Name")
							el-input(v-model="form.name" placeholder="Name of the campaign")
					el-col(:xs="24" :sm="12")
						el-form-item
							template(slot="label")
								| UUID String&nbsp;
								small (Unique Token - For API Use)
							el-input(v-model="form.uuid" placeholder="E.g 4199de26-2be3-4363-a4a9-8d6495a1d625" readonly="")
								el-button(slot="append" icon="el-icon-s-promotion" v-b-tooltip.html.hover-right="", title="Generate new UUID" @click="generateNewGuid")
					el-col(:xs="24" :sm="3")
						el-form-item(label="In Use")
							el-radio-group(v-model="form.in_use")
								el-radio(:label="true") On
								el-radio(:label="false") Off

				//el-divider
				//el-row
					el-col(:span="24")
						h4.text-left
							b Integrations
				//.text-left.mt-3
				//	el-transfer(filterable='',
				//		:titles="['Available', 'Set']"
				//		:filter-method='filterMethod',
				//		target-order="push"
				//		filter-placeholder='Search Integrations',
				//		v-model='integration_list', :data='options.integrations')

				el-row.mt-5(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel


</template>


<style lang="scss">

</style>


<script>
import { v4 } from 'uuid';
import Vue from 'vue';

let $form = { name: '', uuid: '', in_use: false }

	export default {
		async beforeRouteEnter(to, from, next) {
			if(to.name !== 'iframe-edit-campaign' && to.name !== 'iframe-add-campaign') next({name: 'iframe-list'})

			try {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '9e832481-2c11-4021-ba13-0dcc5487e3f7'});
				if(to.name === 'iframe-edit-campaign') {
					let result = await Vue.apix.sendHttpRequest('GET', 'campaign/' + to.params.id)
					next(vm => {
						vm.options = {
							integrations: options.integration_extra.map(e => Object.assign({}, {
								label: e.bn + ' - ' + e.t + (e.n ? ` (${e.n})` : ''),
								key: e.v,
							}))
						}
						vm.originalUUID = result.uuid;
						vm.type = 'edit';
						vm.form = {...$form};
						vm.form.id = to.params.id;
						vm.form.uuid = result.uuid;
						vm.form.name = result.name;
						vm.form.in_use = result.in_use;
					})
				} else {
					next(vm => {
						vm.form = $form;
						vm.options = {
							integrations: options.integration_extra.map(e => Object.assign({}, {
								label: e.bn + ' - ' + e.t + (e.n ? ` (${e.n})` : ''),
								key: e.v,
							}))

						}
					});
				}
			} catch (e) {
				console.error(e);
				next({ name: 'iframe-list' });
			}
		},
		data() {
			return {
				originalUUID: '',
				type: 'create',
				form: {
					name: '',
					uuid: v4(),
					in_use: false
				},
				integration_list: [],
				options: {
					integrations: []
				}
			}
		},
		methods: {
			filterMethod: {

			},
			generateNewGuid() {
				this.form.uuid = v4();
			},
			submit() {
				switch (this.type) {
					case 'create':
						this.$apix.sendHttpRequest('POST', 'campaign/', this.form)
							.then(res => {
								if(res.id) {
									this.$notify.success({title: 'API Response', message: 'Campaign created successfully'});
									this.$router.push({name: 'iframe-list'});
								}
								else this.$notify.error({title: 'API Error', message: 'Not created. Try again'});
							})
							.catch(err => {
								this.$notify.error({title: 'API Error', message: 'Could not create the campaign. Error: ' + err.message});
							});
						break;

					case 'edit':
						this.$apix.sendHttpRequest('PUT', 'campaign/' + this.originalUUID, this.form)
							.then(res => {
								this.$notify.success({title: 'API Response', message: 'Campaign updated successfully'});
								this.$router.push({name: 'iframe-list'});
							})
							.catch(err => {
								this.$notify.error({title: 'API Error', message: 'Could not update the campaign. Error: ' + err.message});

							});
						break;
				}
				this.$apix.sendHttpRequest()
			}
		},
	}
</script>
