<template lang="pug">
	el-form
		el-row(:gutter="10")
			el-col(:xs="24")
				el-form-item(label="By Keyword" label-position="top" label-width="auto")
					el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")
		el-collapse(v-model="activeEntries" @change="toggleCollapse")
			el-collapse-item(v-for="(r,i) of list" :name="i")
				template(slot="title")
					.d-flex.justify-content-between.w-100
						div
							b.ml-2 {{'#' + r.id}}
							b.ml-2  {{ r.brand.name}}&nbsp;
								small ({{ r.integration.type }})
							span.small.ml-4.v-desktop
								b Hash:&nbsp;
								| {{ r.hash }}
							span.small.ml-4.v-desktop
								b(v-if="r.title") Title:&nbsp;
								| {{ r.title }}
							span.small.ml-4.v-mob(v-b-tooltip.html.hover.rigt h="", :title="`${r.title ? `<b>Title:</b> ${r.title}<br> ` : ''}<b>Hash: </b>${r.hash}`")
								b.hide-ultrasmall Hover to view hash
						.mr-3
							el-button(v-b-tooltip.html.hover.left="", title="Edit Spreadsheet", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-spreadsheet', params: { id: r.id }})")
				el-table(id="data-list", v-loading='busy', stripe="", :data='r.worksheet', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
					el-table-column(align="center", label="#", :width="55" )
						template(slot-scope='scope')
							| {{ scope.row.id }}
					el-table-column(align="center", label="External #" )
						template(slot-scope="scope")
							span {{ scope.row.external_id || '-' }}
					el-table-column(align="center", label="Language" )
						template(slot-scope="scope")
							span() {{ scope.row.language || '-' }}
					el-table-column(align="center", label="Lookup Keys" )
						template(slot-scope="scope")
							span.chooseable(v-b-tooltip.html.hover.right="", :title="scope.row.keys")  Hover to view
					el-table-column(align="center", label="Link" )
						template(slot-scope="scope")
							span.chooseable(@click="openLink(`${link}${r.hash}/edit#gid=${scope.row.external_id}`)")  Click To Open
					el-table-column(align="center" label="Actions" :width="115")
						template(slot-scope="scope")
							.d-flex.justify-content-center
								el-button(v-b-tooltip.html.hover.top="", title="Show Integration", type="success", icon="el-icon-tickets", size="small", @click="$emit('googleSheet', r.integration.id)")
								//el-button(v-b-tooltip.html.hover.left="", title="Edit Spreadsheet", type="info", icon="el-icon-edit", size="small", @click="$router.push({name: 'iframe-edit-funnel', params: {id: scope.row.id}})")
								//el-button(v-b-tooltip.html.hover.top="", title="Remove Spreadsheet", type="danger", size="small",  icon="el-icon-remove", @click="removeEntity(r.integration.id)")

		.d-flex.justify-content-between
			b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


			el-row
				el-col(:span="24")
					el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
						el-option(label="5 Per Page", :value="5")
						el-option(label="10 Per Page", :value="10")
						el-option(label="20 Per Page", :value="20")
						el-option(label="50 Per Page", :value="50")
						el-option(label="100 Per Page", :value="100")
						el-option(label="200 Per Page", :value="200")
</template>

<style lang="scss">

</style>

<script lang="js">
	const clearilters = (data) => {
		let body = {...data};

		if(!body.brand_id) delete body.brand_id;
		return {...body}
	};

	export default {
		name: 'google-sheets-list',
		props: ['brand_id'],
		data() {
			return {
				busy: false,
				activeEntries: [],
				list: [],
				count: 0,
				paginate:{
					keyword: '',
					limit: 5,
					page: 1
				}
			}
		},
		async created() {
			await this.fetchData();
		},
		watch: {
			async brand_id() {
				await this.fetchData();
			}
		},
		computed: {
			link() {
				return process.env.VUE_APP_GOOGLE_SHEETS_URL || 'https://docs.google.com/spreadsheets/d/'
			}
		},
		methods: {
			openLink(url) {
				window.open(url, "_blank")
			},
			toggleCollapse() {

			},
			async fetchData() {
				setTimeout(() => {
					this.$apix.sendHttpRequest('GET', 'spreadsheet/list', clearilters(Object.assign({}, {...this.paginate}, {...{brand_id: this.brand_id}})))
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
						})
				}, 0)
			}
		}
	}
</script>
